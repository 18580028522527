
  import { Component, Vue, Watch } from "vue-property-decorator";
  import {
    readAdminEvents,
    readEventsSearchOptions,
    readEventsSearchQuery,
  } from "@/store/admin/getters";
  import {
    dispatchGetAnswersReportByEvent,
    dispatchGetEvents,
  } from "@/store/admin/actions";
  import { debounce } from "@/utils";
  import { formatDate, formatTime, getTimeZone } from "@/date-utils";
  import {
    commitSetEventsSearchOptions,
    commitSetEventsSearchQuery,
  } from "@/store/admin/mutations";

  const debouncedDispatchGetEvents = debounce(dispatchGetEvents, 200);

  @Component
  export default class AdminEvents extends Vue {
    public headers = [
      {
        text: "Title",
        sortable: true,
        value: "title",
        align: "left",
      },
      {
        text: "Date",
        sortable: true,
        value: "start_date",
        align: "left",
      },
      {
        text: "Status",
        sortable: true,
        value: "status",
        align: "left",
      },
      {
        text: "CEU Report",
        value: "ceu_report",
        align: "center",
      },
      {
        text: "Actions",
        value: "actions",
      },
    ];

    loading = false;
    options = {
      sortBy: ["start_date"],
      sortDesc: ["true"],
      page: 1,
    };
    searchQuery: string | null = null;
    snackbar = false;

    get eventsResults() {
      return readAdminEvents(this.$store);
    }

    get events() {
      return this.eventsResults.events;
    }

    get totalEvents() {
      return this.eventsResults.pagination.total;
    }

    async refreshData() {
      this.loading = true;
      await debouncedDispatchGetEvents(this.$store, {
        options: this.options,
        searchQuery: this.searchQuery,
      });
      this.loading = false;
    }

    @Watch("options")
    async onOptionsChange() {
      this.refreshData();
    }

    @Watch("searchQuery")
    async onSearchQueryChange() {
      this.options.page = 1;
      this.refreshData();
    }

    formatDate(date: string, utc_offset: string) {
      return formatDate(date, utc_offset);
    }

    formatTime(date: string, utc_offset: string, time_zone: string) {
      return `${formatTime(date, utc_offset)} ${getTimeZone(utc_offset, time_zone)}`;
    }

    public async mounted() {
      const storedOptions = readEventsSearchOptions(this.$store);
      if (storedOptions) {
        this.options = storedOptions;
      }
      const storedQuery = readEventsSearchQuery(this.$store);
      if (storedQuery) {
        this.searchQuery = storedQuery;
      }
      await this.refreshData();
    }

    beforeRouteLeave(to, from, next) {
      commitSetEventsSearchOptions(this.$store, this.options);
      commitSetEventsSearchQuery(this.$store, this.searchQuery);
      next();
    }

    routeEditEvent(id: string) {
      this.$router.push({ name: "main-admin-events-detail", params: { id } });
    }

    async downloadReport(id: string) {
      const resp = await dispatchGetAnswersReportByEvent(this.$store, {
        eventId: parseInt(id),
      });

      if (resp) {
        var fileName = "Answer_Report";
        if (resp.headers["content-disposition"]?.includes("filename=")) {
          fileName = resp.headers["content-disposition"]
            .split("filename=")[1]
            .replaceAll('"', "");
        }
        this.downloadFile(resp.data, fileName);
      } else {
        this.snackbar = true;
      }
    }

    downloadFile(file: Blob, fileName: string) {
      var fileURL = window.URL.createObjectURL(file);
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", `${fileName}.csv`);
      document.body.appendChild(fileLink);
      fileLink.click();
      document.body.removeChild(fileLink);
    }
  }
